<template>
    <div>
        <div class="container">
            <Breads :links="breadLinks" />
        </div>
        <div class="container">
            <PageHeader :title="$t('Network')" :button-caption="$t('Add a network')" @pageHeaderButtonClick="openModal"/>
        </div>
        <div class="container">
            <TableMain
                @update="updateNetwork"
                v-if="networkList"
                :items="networkList"
                :fields="fields"
                :header-columns="columns"
                @getItems="getNetworkList"
                :itemsMeta="networkMeta"
                :valuesFromRoute="true"
                :hideActions="false"
                :preloader="preloader"
            />
            <ModalComponent :show="showModal" @close="closeModal" :ask-before-leave="askBeforeLeave">
                <form class="form-container" @submit.prevent="submitForm" ref="formNetwork">
                    <div class="title"> {{formTitle}} </div>
                    <InputComponent
                        name="custom_id"
                        :required="true"
                        class="input-component"
                        placeholder="ID"
                        :showLabel="true"
                        :error="errors.custom_id"
                        v-model="networkForEdit.custom_id"
                    />
                    <InputComponent
                        name="name"
                        :required="true"
                        class="input-component"
                        :placeholder="$t('Name')"
                        :showLabel="true"
                        :error="errors.name"
                        v-model="networkForEdit.name"
                    />
                    <SelectComponents
                        name="company_id"
                        :required="true"
                        class="input-component"
                        :placeholder="$t('Company')"
                        :showLabel="true"
                        :options="companiesList"
                        :value="1"
                        :error="errors.company_id"
                        v-model="networkForEdit.company_id"
                    />
                    <div class="btn-container">
                        <button type="submit" class="add">
                            <span v-if="!editFormStatus">{{$t('Add a network')}}</span>
                            <span v-else>{{$t('Save')}}</span>
                        </button>
                        <button type="button" class="cancel" @click="showModal = false"> {{$t('Cancel')}} </button>
                    </div>
                </form>
            </ModalComponent>
        </div>
    </div>
</template>

<script>
import Navbar from "../components/ui/Navbar";
import TableMain from "../components/ui/tables/TableMain";
import Breads from "../components/Breads";
import ModalComponent from "../components/ui/ModalComponent"
import InputComponent from "../components/ui/forms/InputComponent";
import SelectComponents from "../components/ui/forms/SelectComponent";
import PageHeader from "@/components/PageHeader";

export default {
    name: "Network",
    components:{
        SelectComponents,
        TableMain,
        Navbar,
        Breads,
        ModalComponent,
        InputComponent,
        PageHeader
    },
    data () {
        return {
            showModal: false,
            columns: [
                {
                    id: 1,
                    label: "ID",
                    sort: false,
                    attribute: "custom_id",
                },
                {
                    id: 2,
                    label: this.$t('Company'),
                    sort: false,
                    attribute: "company_name",
                },
                {
                    id: 3,
                    label: this.$t('Name'),
                    sort: false,
                    attribute: "name",
                },
                {
                    id: 4,
                    label: this.$t('Token'),
                    sort: false,
                    attribute: "token",
                }
            ],
            networkList: [],
            askBeforeLeave: false,
            preloader: true,
            fields: [
                {
                    type: "search",
                    by: "custom_id",
                },
                {
                    type: "search",
                    by: "company_name",
                },
                {
                    type: "search",
                    by: "name",
                },
            ],
            errors: {},
            breadLinks: [
                {
                    label: this.$t('Network'),
                    to: { name: 'Network' }
                }
            ],
            companiesList: [],
            idForEdit: null,
            editFormStatus: false,
            networkForEdit: {},
            formTitle: '',
            inProgress: false,
            networkMeta: []
        }
    },
    watch:{
        networkForEdit: {
            handler: function(newValue){
                this.askBeforeLeave = true
                console.log(newValue)
            },
            deep: true
        }
    },
    methods:{
        async getNetworkList(query = null){
            if (!query) {
                if (Object.keys(this.$route.query).length === 0) {
                    this.$router.push({ query: Object.assign({}, {page: 1, sort_by: 'id'}) });
                }
                query = this.$route.query
            }

            let resp = await this.api.network.getNetworks(query).catch(this.handleError)

            this.networkMeta = resp.meta

            this.networkList = resp.data

            if (this.networkList && this.companiesList){
                resp.data.forEach(network => {
                    let company = this.companiesList.find(company => {
                        return company.id === network.company_id
                    })
                    if (company){
                        network.company_name = company.label
                    }
                })
            }

            this.preloader = false
        },
        closeModal(){
            this.showModal = false
            this.errors = {}
        },
        openModal(){
            this.formTitle = this.$t('Add a network')
            this.editFormStatus = false
            this.showModal = true
            this.networkForEdit = {}
            setTimeout(() => this.askBeforeLeave = false, 500)
        },
        async submitForm(){
            let formData = new FormData(this.$refs.formNetwork)
            // formData.append('company_id', this.networkForEdit.company_id)

            let resp;
            if (!this.editFormStatus && !this.inProgress){

                this.inProgress = true
                resp = await this.api.network.sentNewNetwork(formData).catch(this.handleError)
                this.inProgress = false

            } else if (!this.inProgress) {

                this.inProgress = true
                resp = await this.api.network.updateNetwork(this.idForEdit, this.networkForEdit).catch(this.handleError)
                this.inProgress = false

            }

            if (resp){
                if (!this.editFormStatus){
                    this.toast.success(this.$t('Network successfully added'))
                } else {
                    this.toast.success(this.$t('Network successfully edited'))
                }
                this.networkForEdit = {}
                this.showModal = false
                this.networkList = null
                this.getNetworkList()
                this.errors = {}
            }
        },
        handleError(resp){
            this.errors = {}
            if (resp.statusCode === 422) {
                this.errors = resp.messages
            }

        },
        async getCompaniesList(){
            let resp = await this.api.companies.getCompaniesList({no_pagination: true})

            resp.data.forEach(company => {
                this.companiesList.push({
                    id: company.id,
                    label: company.name
                })
            })

        },
        async updateNetwork(id){
            this.editFormStatus = true
            this.showModal = true
            this.idForEdit = id
            this.formTitle = this.$t('Edit network')

            let resp = await this.api.network.getNetworkId(id).catch(this.handleError)

            this.networkForEdit = resp.data
            setTimeout(() => this.askBeforeLeave = false, 500)
        },

    },
    created() {
        this.getCompaniesList()
        this.getNetworkList()
    }
}
</script>

<style scoped lang="scss">

.form-container{
    .title{
        font-size: 24px;
        font-weight: 600;
        color: #141213;
        display: flex;
        justify-content: center;
        margin-bottom: 7px;
    }
    .input-component{
        margin-top: 30px;
    }
    .btn-container{
        display: flex;
        justify-content: center;
        margin-top: 50px;
        .add{
            margin-right: 15px;
            width: 173px;
            height: 45px;
            border-radius: 2px;
            background-color: $color_darkblue;
            display: flex;
            justify-content: center;
            align-items: center;
            border: none;
            text-transform: uppercase;
            font-size: 12px;
            font-weight: 900;
            letter-spacing: 2px;
            text-align: center;
            color: white;
            &:hover{
                color: $color_lightpink;
                box-shadow: 0 4px 10px 0 rgba(53, 0, 212, 0.25);
            }
        }
        .cancel{
            width: 172px;
            height: 45px;
            border-radius: 2px;
            border: solid 1px $color_darkblue;
            background-color: white;
            text-transform: uppercase;
            justify-content: center;
            align-items: center;
            font-size: 12px;
            font-weight: 900;
            letter-spacing: 2px;
            color: #141213;
        }
    }
}

::v-deep{
    .breadcrumb{
        margin-top: 8px;
        .breadcrumb__item:last-child a{
            color: #141213;
            cursor: default;
        }
    }
    .modal__content{
        @media screen and (min-width: $xl) {
            width: 750px;
        }
    }
    .form-group__input, .form-control{
        background-color: #F8FAFF;
    }
    //.form-group__error, .select-element__error{
    //    right: auto;
    //    bottom: auto;
    //}
}
</style>
