<template>
    <div class="form-group">
        <label v-if="showLabel" class="form-group__label"> <span v-if="required" class="required">*</span> {{ placeholder }}</label>
        <input
            :type="type"
            :value="value"
            :name="name"
            class="form-control form-group__input"
            :autocomplete="autocomplete"
            :placeholder="!showLabel && placeholder ? placeholder : ''"
            v-on:input="inputChange"
            :class="{ 'is-invalid': errorsList }"
            :disabled="disabled"
        />
        <span class="material-icons form-group__icon" v-if="iconCode !== null">{{ iconCode }}</span>
        <span v-if="errorsList" class="form-group__error">{{ Array.isArray(errorsList) ? errorsList[0] : errorsList }}</span>
    </div>
</template>

<script>
export default {
    name: "InputComponent",
    data(){
      return{
          errorsList: null,
      }
    },
    props: {
        required:{
            type: Boolean,
            required: false
        },
        name: {
            type: String,
            required: true
        },
        value: {
            required: false
        },
        autocomplete: {
            required: false,
            type: String,
            default: "on"
        },
        disabled: {
            required: false,
            type: Boolean,
            default: false
        },
        type: {
            type: String,
            required: false,
            default: "text"
        },
        icon: {
            type: String,
            required: false
        },
        placeholder: {
            type: String,
            required: false
        },
        showLabel: {
            type: Boolean,
            default: false
        },
        error: {
            required: false,
            default: false
        }
    },
    watch:{
        error(){
            this.errorsList = this.error
        }
    },
    computed: {
        iconCode() {
            return this.icon ? this.icon : null;
        }
    },
    methods:{
        inputChange(e){
            this.errorsList = null
            this.$emit('input', e.target.value)
        }
    }
};
</script>

<style lang="scss" scoped>
/* Hide arrows for input type number
 Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}

.required{
    color: #ff0032;
}

.form-control{
    &:focus{
        outline: none;
        box-shadow: none;
        border-radius: 2px;
        border: solid 1px $color_lightgray;
    }
}

.form-group {

    &__label {
        margin-bottom: 8px;
        color: $color_gray;
    }

    &__input {
        border: solid 1px #EEF3FD;
        border-radius: 2px;
        color: $color_black;
        background-color: fade-out($color_light, .6);
        padding: 10px 15px;
        font-size: 15px;
        font-family: $ff-primary;
        width: 100%;
        box-sizing: border-box;

        &::placeholder {
            color: $color_black;
        }

        &.is-invalid {
            border-color: $color_red;
        }

        &[disabled] {
            color: $color_gray;
            cursor: initial;
            border: solid 1px $color_light;

            &::placeholder {
                color: $color_gray;
            }
        }
    }

    &__icon {
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translate(0, -50%);
        font-size: 15px;
    }

    &__error {
        //font-size: 13px;
        //color: $color_red;
        //position: absolute;
        //right: 0;
        //bottom: auto;
        font-size: 13px;
        color: #FF496D;
        position: absolute;
        right: 0;
        bottom: auto;
        max-width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    input[type='password']{
        &:before{
            content: ""
        }
    }
}

</style>
