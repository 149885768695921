import Vue from 'vue'
import Echo from 'laravel-echo'

//let encrypted = process.env.NODE_ENV !== 'development';

window.Pusher = require('pusher-js');

export var echo_instance = new Echo({
    broadcaster: 'pusher',
    key: 'cinema',
    wsHost: window.location.hostname,
    wsPort: 8314,
    wssPort: 443,
    forceTLS: false,
    disableStats: true,
    authEndpoint: "/api/broadcasting/auth",
    encrypted: false,
    auth: {
        headers: {
            Accept: 'application/json'
        }
    }
})

Vue.prototype.$echo = echo_instance
